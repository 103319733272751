// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-constitution-tsx": () => import("./../../../src/pages/about/constitution.tsx" /* webpackChunkName: "component---src-pages-about-constitution-tsx" */),
  "component---src-pages-about-grievance-policy-tsx": () => import("./../../../src/pages/about/grievance-policy.tsx" /* webpackChunkName: "component---src-pages-about-grievance-policy-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-previous-committee-tsx": () => import("./../../../src/pages/about/previous-committee.tsx" /* webpackChunkName: "component---src-pages-about-previous-committee-tsx" */),
  "component---src-pages-about-team-tsx": () => import("./../../../src/pages/about/team.tsx" /* webpackChunkName: "component---src-pages-about-team-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-index-tsx": () => import("./../../../src/pages/join/index.tsx" /* webpackChunkName: "component---src-pages-join-index-tsx" */),
  "component---src-pages-publications-newsletters-tsx": () => import("./../../../src/pages/publications/newsletters.tsx" /* webpackChunkName: "component---src-pages-publications-newsletters-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("./../../../src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-sponsors-index-tsx": () => import("./../../../src/pages/sponsors/index.tsx" /* webpackChunkName: "component---src-pages-sponsors-index-tsx" */),
  "component---src-pages-sponsors-past-sponsors-tsx": () => import("./../../../src/pages/sponsors/past-sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-past-sponsors-tsx" */),
  "component---src-pages-typography-tsx": () => import("./../../../src/pages/typography.tsx" /* webpackChunkName: "component---src-pages-typography-tsx" */)
}

